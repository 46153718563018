import React from "react";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import {RecoilRoot} from "recoil"


const TopLayout = ({ children, theme }) => {
  return (
    <RecoilRoot>
      <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
    </RecoilRoot>
  );
}

export default TopLayout