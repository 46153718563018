module.exports = [{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WBXXHRK","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            platform: \"gatsby\",\n            originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search\n          }\n        }"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-123201104-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1100221470174991"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"InspiraFarms","short_name":"IF","start_url":"/","background_color":"#FFB500","theme_color":"#FB500","display":"minimal-ui","icon":"src/assets/images/InspiraFarmsIcon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"96bf0b38e069724db23742538030b109"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
