import React from "react"
import ReactDOM from 'react-dom'
import { theme } from "./src/gatsby-theme-material-ui-top-layout/theme"
import TopLayout from './src/gatsby-theme-material-ui-top-layout/components/top-layout'

export const wrapRootElement = ({ element }) => {
  return (
        <TopLayout theme={theme}>
          {element}
          </TopLayout>
  )
}

// weird bug with Material UI, without this everything gets messed up
// https://github.com/gatsbyjs/gatsby/issues/8237
// TODO: updating material UI might help here in the future
// WARNING: this solution is not performant

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}