// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-configurator-index-jsx": () => import("./../src/pages/configurator/index.jsx" /* webpackChunkName: "component---src-pages-configurator-index-jsx" */),
  "component---src-pages-configurator-result-index-jsx": () => import("./../src/pages/configurator/result/index.jsx" /* webpackChunkName: "component---src-pages-configurator-result-index-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

