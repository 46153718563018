import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  shadows: [
    '',
    '0px 5px 6px rgba(33, 37, 41, 0.13), 0px 10px 14px rgba(33, 37, 41, 0.11)',
    '0px 11px 15px rgba(33, 37, 41, 0.13), 0px 24px 38px rgba(33, 37, 41, 0.11)',
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',

  ],
  shape: {
    borderRadius: 15,
  }, 
  palette: {

    primary: {
      main: "#01AEBE",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFB500",
    },
    tertiary: {
      main: "#60B130",
    },
    quaternary: {
      main: "#996C57"
    },
    background: {
      default: "#FFFFFF"
    },
    neutral: {
      main: '#F9F9F9'
    }
  },
  typography: {
    // fontFamily: 'Josefin Sans, Roboto',
    body1: {
      fontSize: 18
    },
    h1: {
      '@media (max-width:600px)': {
        fontSize: '2.5rem',
      },
      fontFamily: 'Josefin Sans',
      fontWeight: "bold",
      fontSize: 70
    },
    h2: {
      fontWeight: "400",
      fontSize: 26
    },
    h5: {
      fontFamily: 'Josefin Sans',
      fontWeight: "bold",
    },
    h6: {
      fontSize: 18,
      fontWeight: "400"
    },
    button: {
      textTransform: "none",
      fontSize: 18
    },
    body1: {
      color: "#656565"
    }
  },
  overrides: {
    MuiButton: {
      outlined: {
        borderRadius: 30,
        fontWeight: "400",
        padding: "12px 25px"
      },
      root: {
        borderRadius: 30,
        padding: "12px 25px"
      }, 
      text: {
        textTransform: "none",
      }
    },
  }
})

// theme.typography = {
//   h1: {
//     '@media (min-width:600px)': {
//       fontSize: '1.5rem',
//     },
//     // [theme.breakpoints.up('md')]: {
//     //   fontSize: "30px",
//     // },
//   }
// }
export {theme}